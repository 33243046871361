body {
  font-family: 'Helvetica', sans-serif;
  margin: 0; }

* {
  box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto; }

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none; } }

p {
  margin: 0; }

a {
  text-decoration: none; }

.title {
  margin: 0; }

.container {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto; }

.small_container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto; }

.contact_page {
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 3px solid #ecd85c;
  position: relative; }

.show_mobile {
  display: none; }
