// Header

.header_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 43px 0 10px 0;
  &__logo {
    img {
      width: 170px; } }
  &__menu {
    ul {
      display: flex;
      .menu-item-has-children {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          bottom: 5px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 0 solid rgba(93, 93, 93, 0.36);
          transition: 0.5s; } }
      .current-menu-item {
        a {
          border-bottom: 1px solid rgba(93, 93, 93, 0.36); } }
      li {
        padding: 15px;
        transition: 0.5s;
        position: relative;
        &:hover {
          &:before {
            border-top: 7px solid rgba(93, 93, 93, 0.36); }
          a {
            border-bottom: 1px solid rgba(93, 93, 93, 0.36); }
          .sub-menu {
            display: flex;
            opacity: 1;
            visibility: visible;
            z-index: 1;
            li {
              &:before {
                display: none; }
              a {
                border-bottom: 0; } } } }
        &:last-child {
          padding-right: 0; }
        a {
          color: #8e8e8e;
          font-size: 14px;
          font-weight: 400;
          line-height: 13.58px;
          transition: 0.5s; }
        .sub-menu {
          position: absolute;
          top: 45px;
          width: 240px;
          border: 1px solid #e4c533;
          background-color: #ffffff;
          display: none;
          flex-direction: column;
          transition: 0.5s;
          li {
            padding: 0;
            position: relative;
            &:hover {
              transition: 0.5s;
              a {
                background-color: #f5e764; }
              .sub-menu {
                display: block; } }
            a {
              display: block;
              padding: 3px 15px;
              margin: 5px 0;
              color: #5a5a5a;
              font-size: 11px;
              font-weight: 300;
              transition: 0.5s; }
            .sub-menu {
              display: none;
              position: absolute;
              top: 0;
              right: 238px;
              width: 301px;
              background-color: #f5e764;
              border: 0;
              li {
                &:hover {
                  transition: 0.5s;
                  a {
                    color: #fff;
                    background-color: #8e8e8e; } }
                a {
                  display: block;
                  padding: 3px 15px;
                  margin: 5px 0;
                  color: #5a5a5a;
                  font-size: 11px;
                  font-weight: 400;
                  transition: 0.5s; } } } } } } } } }

.header_consult {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  transition: 0.5s;
  height: 33px;
  z-index: 1;
  &__form {
    display: none;
    .home_form__title {
      color: #5a5a5a;
      font-size: 17px;
      font-weight: 400;
      line-height: 16.37px;
      text-transform: uppercase; }
    .home_form__item {
      input[type="submit"] {
        background-color: #5a5a5a;
        color: #fff;
        background-image: none;
        transition: 0.5s;
        &:hover {
          opacity: 0.8;
          transition: 0.5s;
          box-shadow: 0 10px 44px -15px rgba(0,0,0,0.75); } } } }

  .consult_open {
    display: flex;
    justify-content: center;
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.58px;
    text-transform: uppercase;
    letter-spacing: 2.78px;
    position: relative;
    padding: 10px 0;
    &:before {
      position: absolute;
      content: '';
      bottom: -10px;
      width: 27px;
      height: 16px;
      background-image: url("../img/icon_open.svg");
      background-repeat: no-repeat;
      background-size: cover; } }
  .consult_close {
    display: none;
    position: absolute;
    bottom: -20px;
    right: calc(50% - 25px);
    width: 50px; } }

.header_consult.open {
  height: 90vh;
  width: 100%;
  .header_consult__form {
    display: block; }
  .consult_open {
    display: none;
    img {
      background-color: #fff; } }
  .consult_close {
    display: inline-block;
    img {
      background-color: #fff;
      width: 50px; } } }

// Mobile menu

.lang_mobile {
  display: none;
  @media only screen and (max-width: $r-768 - 1) {
    display: block; }
  a {
    color: #8e8e8e;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.58px;
    transition: .5s; } }


.nav_mobile {
  display: none;
  a {
    img {
      width: 40px; } }
  .menu {
    display: none;
    position: absolute;
    left: -100%;
    width: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    background: #fff;
    transition: 0.5s;
    li {
      a {
        color: #8e8e8e;
        font-size: 14px;
        font-weight: 400;
        line-height: 13.58px; }
      ul {
        margin: 10px 0;
        padding-left: 20px;
        li {
          list-style: circle;
          ul {
            margin: 10px 0;
            padding-left: 20px;
            li {
              list-style: disc; } } } } } }
  .menu_open {
    width: 80%;
    left: 0;
    transition: 0.5s;
    padding: 20px 15px; } }

.over_visible {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.11); }

.overflow {
  position: relative;
  height: 100%; }

