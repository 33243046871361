// Price

.price {
  padding: 0 0 80px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 3px solid #ecd85c;
  position: relative;
  &__title {
    text-align: center;
    margin-bottom: 35px;
    .title {
      color: #010101;
      font-size: 25px;
      font-weight: 700;
      line-height: 21.49px; } }
  &__text {
    p {
      margin-bottom: 15px; }
    p, ul li {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 13.58px; }
    ul li {
      margin-bottom: 5px; } } }
