// Footer

.footer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 35px 0 55px 0;
  position: relative;
  margin-top: -10px;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

.footer_logo {
  img {
    width: 160px; } }

.footer_contacts {
  width: 60%;
  max-width: 100%; }

.footer_contacts {
  &__title {
    margin-bottom: 21px;
    .title {
      color: #fffefe;
      font-size: 20px;
      font-weight: 400;
      line-height: 21.33px; } }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; } }

.footer_contacts_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 93px;
  padding-right: 20px;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 10px;
    width: 2px;
    height: 93px;
    background-color: rgba(255, 255, 255, 0.28); }
  &:last-child {
    &:before {
      display: none; } }
  &__block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px; }
  &__img {
    margin-right: 10px;
    img {
      height: 15px; } }
  &__text {
    .textwidget {
      display: flex;
      flex-direction: column; }
    span {
      line-height: 1; }
    p {
      display: flex;
      flex-direction: column; }
    p,
    a {
      color: #fffefe;
      font-size: 13px;
      font-weight: 400;
      line-height: 13.41px;
      margin-bottom: 0; }
    a {
      transition: 0.5s;
      &:hover {
        color: #f5e544; } } } }
