// Home header

.home_header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 740px; }

.header_contacts {
  background-color: rgba(142, 142, 142, 0.71);
  padding: 10px 0;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

.header_contacts_item {
  display: flex;
  align-items: center;
  img {
    height: 20px;
    margin-right: 10px; }
  &__info {
    .textwidget {
      display: flex;
      flex-direction: column; }
    span {
      line-height: 1; }
    p {
      display: flex;
      flex-direction: column; }
    p,
    a {
      color: #fffefe;
      font-size: 13px;
      font-weight: 500;
      line-height: 11px;
      margin: 0; }
    a {
      transition: 0.5s;
      &:hover {
        color: #f5e544; } } } }

.home_header_text {
  padding-top: 200px;
  display: flex;
  justify-content: flex-end;
  &__info {
    width: 500px;
    max-width: 100%;
    .title {
      background: -webkit-linear-gradient(9deg, #d9bc4c 0%, #f1e060 48%, #e8d45a 100%);
      -webkit-background-clip: text;
      text-shadow: 3px 4px 5px rgba(0, 0, 0, 0.27);
      color: #f3e462;
      font-size: 61px;
      font-weight: 700;
      line-height: 59.01px;
      text-transform: uppercase; }
    p {
      text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.27);
      color: #ffffff;
      font-size: 33px;
      font-weight: 500;
      line-height: 25.13px; } } }

// Home services

.home_services {
  background-color: #5a5a5a;
  padding: 60px 0 90px 0;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &__title {
    text-align: center;
    margin-bottom: 50px;
    .title {
      color: #ffffff;
      font-size: 25px;
      font-weight: 400;
      line-height: 24.6px; } }
  &__wrapper {
    display: flex;
    justify-content: space-between; } }

.home_services_item {
  width: 105px;
  max-width: 100%;
  &:hover {
    transition: 0.5s;
    img {
      transform: scale(1.2); } }
  &__img {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    img {
      width: 55px;
      transition: 0.5s; } }
  p {
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.58px; } }

// Home text

.home_text {
  padding: 90px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &__title {
    text-align: center;
    margin-bottom: 35px;
    .title {
      color: #010101;
      font-size: 25px;
      font-weight: 400;
      line-height: 21.49px; } }
  &__text {
    p {
      margin-bottom: 15px; }
    p, ul li {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5; }
    ul {
      margin-left: 15px;
      li {
        margin-bottom: 15px; } } } }

// Home form

.home_form {
  padding: 85px 0 120px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 37px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &__title {
    text-align: center;
    margin-bottom: 25px;
    .title {
      color: #ffffff;
      font-size: 25px;
      font-weight: 400;
      line-height: 24.6px; } }
  &__item {
    form {
      display: flex;
      flex-direction: column;
      width: 365px;
      max-width: 100%;
      margin: 0 auto;
      .wpcf7-not-valid-tip {
        position: absolute;
        font-size: 10px;
        top: 30px; }
      .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
        margin: 0;
        font-size: 12px; }
      input {
        height: 44px;
        margin-bottom: 11px;
        padding: 0 15px; }
      textarea {
        margin-bottom: 18px;
        padding: 25px 15px 0 15px;
        height: 143px; }
      input, textarea {
        width: 100%;
        color: #5a5a5a;
        font-size: 14px;
        font-weight: 300;
        line-height: 13.58px;
        outline: 0;
        border: 0;
        background-color: #fff;
        resize: none;
        &::placeholder {
          color: #5a5a5a;
          font-size: 14px;
          font-weight: 300;
          line-height: 13.58px; } }
      input[type="submit"] {
        background-image: url("../img/bg_btn.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: #5a5a5a;
        font-size: 14px;
        font-weight: 400;
        line-height: 13.58px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
          opacity: 0.8;
          transition: 0.5s;
          box-shadow: 0 10px 44px -15px rgba(0,0,0,0.75); } } } } }




