// Contact page

.contacts_page {
  border-top: 3px solid #ecd85c;
  padding: 50px 0;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

.contacts_map {
  width: 45%; }

.contacts_info {
  width: 50%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .header_contacts_item {
    width: 45%;
    margin-bottom: 15px;
    &__info {
      p, a {
        font-size: 17px;
        line-height: 1; } } } }

.messangers {
  display: flex;
  width: 100%;
  &__item {
    margin-right: 15px;
    i {
      color: #d6c73d;
      font-size: 24px; } } }



