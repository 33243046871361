// Services

.service {
  padding: 0 0 80px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 3px solid #ecd85c;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &__title {
    text-align: center;
    margin-bottom: 75px;
    .title {
      color: #000000;
      font-size: 25px;
      font-weight: 500;
      line-height: 24.6px; } }
  &__list {
    display: flex;
    justify-content: space-between;
    ul {
      width: 45%;
      max-width: 100%;
      li {
        margin-bottom: 12px;
        padding-left: 15px;
        position: relative;
        display: flex;
        align-items: flex-start;
        a {
          color: #000000;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          transition: 0.5s;
          &:hover {
            transition: 0.5s;
            opacity: 0.6; } }
        &:before {
          position: absolute;
          content: '';
          top: 3px;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ecd85c; } } } } }

.breadcrumbs_block {
  padding: 40px 0 0 0;
  display: flex;
  align-items: center;
  margin-bottom: 75px;
  .breadcrumbs {
    display: flex; }
  span {
    display: flex; }
  a {
    display: flex;
    align-items: center;
    color: #8e8e8e;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    margin: 0 10px;
    transition: 0.5s;
    &:hover {
      opacity: 0.8; } }
  .current-item {
    margin: 0 10px;
    color: #8e8e8e;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ecd85c; }
  .bread_line {
    color: #ecd85c;
    font-size: 22px;
    font-weight: 300;
    line-height: 21.08px; } }

// Show

.service_show {
  padding: 75px 0 90px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &__title {
    text-align: center;
    margin-bottom: 38px;
    .title {
      color: #ffffff;
      font-size: 25px;
      font-weight: 500;
      line-height: 21.49px; } }
  &__text {
    p, ul li {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
      line-height: 1.5;
      b {
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 13.58px;
        margin-bottom: 5px; } } } }

// Service sub

.service_sub {
  padding: 0 0 80px 0;
  border-top: 3px solid #ecd85c;
  &__title {
    text-align: center;
    margin-bottom: 35px;
    .title {
      color: #010101;
      font-size: 25px;
      font-weight: 700;
      line-height: 21.49px; } }
  &__text {
    p {
      margin-bottom: 15px; }
    p, ul li {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5; }
    ul {
      margin-left: 15px;
      li {
        margin-bottom: 15px; } } } }

// Service button

.service_btn {
  padding: 95px 0;
  position: relative;
  background-color: #5a5a5a;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../img/line.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../img/bg_btn.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 461px;
    max-width: 100%;
    height: 62px;
    margin: 0 auto;
    color: #202020;
    font-size: 22px;
    font-weight: 400;
    line-height: 21.45px;
    text-transform: uppercase;
    position: relative;
    transition: 0.5s;
    &:hover {
      opacity: 0.8; }
    &:before {
      position: absolute;
      content: '';
      background-image: url("../img/pseudo_btn.png");
      width: 489px;
      height: 89px; } } }
