$r-768: 768px;
$r-992: 992px;
$r-1024: 1024px;
$r-1200: 1200px;

body {

  // break max-768
  @media only screen and (max-width: $r-768 - 1) {
    .show_desctop {
      display: none; }
    .show_mobile {
      display: block; }
    .nav_mobile {
      display: block;
      .menu {
        display: block; } }
    .service_btn a:before {
      display: none; }
    .header_nav {
      &__menu {
        display: none; } }
    .header_contacts__wrapper {
      flex-wrap: wrap; }
    .header_contacts_item {
      width: 100%;
      margin-bottom: 15px;
      &:nth-child(4) {
        width: 100%; } }
    .home_header {
      min-height: 80vh;
      &_text {
        padding-top: 100px; } }
    .header_contacts_item__info a, .header_contacts_item__info p {
      font-size: 12px; }
    .home_header_text {
      &__info {
        .title {
          font-size: 36px; }
        p {
          font-size: 24px; } } }
    .home_services__wrapper {
      flex-wrap: wrap; }
    .home_services_item {
      width: 45%;
      margin-bottom: 15px; }
    .footer_contacts__wrapper {
      flex-wrap: wrap; }
    .footer_contacts_item {
      width: 100%;
      margin-bottom: 15px;
      &:before {
        display: none; } }
    .contacts_page__wrapper {
      flex-wrap: wrap; }
    .contacts_info, .contacts_map {
      width: 100%;
      margin-bottom: 20px;
      .header_contacts_item {
        width: 100%; } } }

  // break max-992
  @media only screen and (min-width: $r-768) and (max-width: $r-992) {
    .home_services__wrapper,
    .header_contacts_wrap {
      flex-wrap: wrap; }
    .home_services_item {
      width: 25%;
      padding: 0 20px;
      margin-bottom: 30px; }
    .header_contacts_item {
      width: 30%;
      margin-bottom: 10px; }
    .footer_contacts_item {
      width: 31%; } }

  // break max-992
  @media only screen and (max-width: $r-992) {
    .footer_wrap {
      flex-wrap: wrap; }
    .footer_logo {
      margin-bottom: 30px; }
    .footer_logo,
    .footer_contacts {
      width: 100%; } }

  // break max-1200
  @media only screen and (max-width: $r-1200) {
    .container,
    .small_container {
      padding: 0 15px; } } }
